import BasePage from '@/found/components/table_page';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      requestType: 'GET',
    };
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },
  },
  created() {
    this.getConfigList('quarterly_score_report_list');
  },
};
