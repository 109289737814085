<template>
  <div class="home">
    <Page :paramsProps="{ scoreId: formConfig.scoreId }" />
    <div slot="footer" class="dialog-footer">
      <el-button
        type="danger"
        @click="closeModal"
        size="small"
        icon="el-icon-close"
        >关闭</el-button
      >
    </div>
  </div>
</template>

<script>
import Page from './detail';

export default {
  name: 'detail',
  props: {
    formConfig: Object,
  },
  components: {
    Page,
  },
  methods: {
    // 关闭弹窗
    closeModal() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang='less' scoped>
.home {
  height: calc(100vh - 166px);
}
</style>
